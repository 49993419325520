import http from './base/http';
import Config from '@/config';
import {
  Zodiac,
  CURRENCY_DEFAULT,
  LOCALE_CODE_DEFAULT,
} from '@/helper/constants';
import { coustomInterval } from '@/helper/util';
import { AddonsLog } from '@/helper/log';
import { handleAACheckoutError } from '@/helper/error-handler';

const userContext = {
  currency: CURRENCY_DEFAULT,
  geoId: Zodiac.GEO_ID,
  locale: LOCALE_CODE_DEFAULT,
};

export const checkPriceWithoutPolling = async <T>(
  tripId,
  airlineProfile,
  tokenInfo: TokensExternalAuth,
) => {
  const url = `${Config.ZODIAC.SERVICE_ENDPOINT}/fp/checkout/v3/page-data`;

  const headers = {
    channel_hash: Config.ZODIAC.CHANNEL_HASH,
    'Ga-Id': generateGaId(tokenInfo.userId),
  };

  const data = {
    userContext,
    flightDetails: {
      tripId: [`${tripId}`],
      airlineProfile: airlineProfile,
    },
    validateFlights: false,
    sso: {
      accessToken: tokenInfo.accessToken,
      refreshToken: tokenInfo.refreshToken,
      id: tokenInfo.userId,
    },
  };

  return http.post<T>(url, data, { headers });
};

export const checkPrice = async <T>(
  tripId,
  airlineProfile,
  tokenInfo: TokensExternalAuth,
) => {
  let count = 0;
  let isValid = false;
  let pageData = null;
  const polling = async (stop) => {
    pageData = await checkPriceWithoutPolling(
      tripId,
      airlineProfile,
      tokenInfo,
    );
    count++;

    if (
      pageData.flightDetails &&
      pageData.flightDetails.requiresValidation === false
    ) {
      isValid = true;
      stop();
    } else if (count == Zodiac.MAX_COUNT_POLLING_CHECKP_PRICE) {
      stop();
    }

    return pageData;
  };
  await coustomInterval(polling, Zodiac.GAP_POLLING_CHECKP_PRICE).start();

  return {
    isValid,
    pageData,
  };
};

export const generateGaId = (userId) => `GA-WMP-${userId}`;

export const checkoutV3 = async <T>(data) => {
  const url = `${Config.ZODIAC.SERVICE_ENDPOINT}/fp/checkout/v3/initiate`;
  const userId = data.loggedInUserInfo?.ssoDetails?.userId;

  const headers = {
    channel_hash: Config.ZODIAC.CHANNEL_HASH,
    'user-type': Zodiac.USER_TYPE_MEMBER,
    'Ga-Id': generateGaId(userId),
  };

  try {
    const res = await http.post<T>(url, data, { headers });
    AddonsLog.logCheckoutSuccess({ url, headers, data }, { data: res });
    return res;
  } catch (error) {
    AddonsLog.logCheckoutFailure({ url, headers, data }, error?.response);
    handleAACheckoutError(error);
  }
};

const getAncillaryRequestData = (data, includeAncillary: Array<string>) => {
  return {
    userContext: {
      currency: 'CNY',
      geoId: 'CAN',
      locale: 'zh-cn',
    },
    flightDetails: {
      tripId: data.tripIds,
      airlineProfile: 'dotrez',
    },
    validateFlights: false,
    sso: {
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      userId: data.userId,
    },
    includeAncillary,
  };
};

export const getAncillaryItem = async <T>(data: any, items: string[]) => {
  const url = `${Config.ZODIAC.SERVICE_ENDPOINT}/fp/checkout/v4/page-data?include_list=ancillary,locale`;

  const headers = {
    channel_hash: Config.ZODIAC.CHANNEL_HASH,
    'user-type': Zodiac.USER_TYPE_MEMBER,
    'Ga-Id': generateGaId(data.userId),
  };

  const requestData = getAncillaryRequestData(data, items);

  return http.post<T>(url, requestData, { headers });
};
export const getAncillaryBaggage = async <T>(data) => {
  return getAncillaryItem<T>(data, ['baggage']);
};
export const getAncillaryInsurance = async <T>(data) => {
  return getAncillaryItem<T>(data, ['insurance']);
};
export const getAncillaryWheelchair = async <T>(data) => {
  return getAncillaryItem<T>(data, ['wheelchair']);
};

export const checkout = async <T>(data) => {
  const url = `${Config.ZODIAC.SERVICE_ENDPOINT}/fp/checkout/v1/initiate`;

  const headers = {
    channel_hash: Config.ZODIAC.CHANNEL_HASH,
  };

  return http.post<T>(url, data, { headers });
};

export const getPageData = async <T>(
  tripIds,
  airlineProfile,
  tokenInfo: TokensExternalAuth,
  includeAncillary: AACheckout.AncillaryType[] = [],
  includeList = [],
) => {
  const url = `${Config.ZODIAC.SERVICE_ENDPOINT}/fp/checkout/v4/page-data${
    includeList.length > 0 ? `?include_list=${includeList.join(',')}` : ''
  }`;

  const headers = {
    channel_hash: Config.ZODIAC.CHANNEL_HASH,
    'user-type': Zodiac.USER_TYPE_MEMBER,
    'Ga-Id': generateGaId(tokenInfo.userId),
  };

  const data = {
    userContext,
    flightDetails: {
      tripId: tripIds,
      airlineProfile: airlineProfile,
    },
    includeAncillary,
    validateFlights: false,
    sso: {
      accessToken: tokenInfo.accessToken,
      refreshToken: tokenInfo.refreshToken,
      userId: tokenInfo.userId,
    },
  };

  const isFlightDetail = isRequestFlightDetail(includeList);
  try {
    const res = await http.post<T>(url, data, { headers });

    if (isFlightDetail) {
      AddonsLog.logFlightDetailsSuccess({ url, headers, data }, { data: res });
    }

    return res;
  } catch (error) {
    if (isFlightDetail) {
      AddonsLog.logFlightDetailsFailure(
        { url, headers, data },
        error?.response,
      );
    }

    throw error;
  }
};

const isRequestFlightDetail = (includeList = []) => {
  return includeList.length === 0;
};
