import {
  convertMoneyToDefaultCurrency,
  traverseObj,
} from '@/helper/util/common';
import { CURRENCY_DEFAULT } from '@/helper/constants';

export class FastPassParser {
  private pageData;
  private conversionRates;

  constructor(pageData, conversionRates) {
    this.pageData = pageData;
    this.conversionRates = conversionRates;
  }

  public parse() {
    const { ancillary: originalAncillary = {} } = this.pageData;

    const fastPass = originalAncillary.fastPass || {};
    const immigrationFastLane = originalAncillary.immigrationFastLane || {};
    const aggregatedFastPassAndIMMG = this.aggregateFastPassAndIMMG(
      fastPass,
      immigrationFastLane,
    );
    const { startPrice = '', isPaxDependent, measure, metaData } = fastPass;
    const { metaData: immigrationFastLaneMetaData } = immigrationFastLane;

    const fastPassData = this.getFastPassData(aggregatedFastPassAndIMMG);
    const fastPassDescription = metaData
      ? this.getFastPassDescription(metaData, immigrationFastLaneMetaData)
      : null;

    return {
      startPrice,
      isPaxDependent,
      measure,
      fastPassData,
      fastPassDescription,
    };
  }

  private getFastPassData(fastPassData): AACheckout.FastPassData {
    const newFastPassData = this.convertPrice(fastPassData, ['price']);

    return this.convertNumber(newFastPassData, ['limitPerPassenger']);
  }

  private getFastPassDescription(
    metaData,
    immigrationFastLaneMetaData,
  ): AACheckout.FastPassDescription {
    return {
      fastPass: metaData.map((obj) => {
        const { body } = obj;

        return {
          items: body.map((item) => ({
            icon: 'CheckIcon',
            iconColor: 'green',
            text: item.text,
            textColor: 'greyDark',
          })),
        };
      }),
      immigrationFastLane: (immigrationFastLaneMetaData || []).map((obj) => {
        const { body } = obj;

        return {
          items: body.map((item) => ({
            icon: 'CheckIcon',
            iconColor: 'green',
            text: item.text,
            textColor: 'greyDark',
          })),
        };
      }),
    };
  }

  private convertPrice(obj, keys) {
    return traverseObj(obj, keys, (obj, prop) => {
      if (obj[prop] !== null && !isNaN(obj[prop])) {
        obj[prop].price = convertMoneyToDefaultCurrency(
          Number(obj[prop].price),
          obj[prop].currency,
          this.conversionRates,
        );
        obj[prop].currency = CURRENCY_DEFAULT;
      }
    });
  }

  private convertNumber(obj, keys) {
    return traverseObj(obj, keys, (obj, prop) => {
      if (obj[prop] !== null && !isNaN(obj[prop])) {
        obj[prop] = Number(obj[prop]);
      }
    });
  }

  private aggregateFastPassAndIMMG = (
    fastPass: any,
    immigrationFastLane: any,
  ): any[] => {
    const { data: fastPassData } = fastPass;
    const { data: immigrationFastLaneData } = immigrationFastLane || {};

    const aggregatedFPAndIMMG = JSON.parse(JSON.stringify(fastPassData));

    aggregatedFPAndIMMG.forEach((fpData) => {
      if (!fpData) {
        return;
      }
      const { designator } = fpData;
      const { departureStation, arrivalStation } = designator;

      const immgData = (immigrationFastLaneData || []).find(
        (immg) =>
          immg.designator.departureStation === departureStation &&
          immg.designator.arrivalStation === arrivalStation,
      );

      if (!immgData) {
        return;
      }

      const immgDataAncillaryList = immgData.ancillarylist as any[];
      const immgList = immgDataAncillaryList?.filter(
        (immg) => immg.immigrationFastLane?.length > 0,
      );

      if (!immgList) {
        return;
      }

      fpData.ancillarylist.immigrationFastLane = JSON.parse(
        JSON.stringify(immgList),
      );
    });

    return aggregatedFPAndIMMG?.filter((fpAndIMMG) => fpAndIMMG !== null);
  };
}
