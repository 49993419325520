import LogAPI from '../base/log.api';
import { ErrorLogBase } from './types';

export class FrontendLogger {
  private static getCommonFields() {
    return {
      timestamp: new Date().toISOString(),
      url: window.location.href,
      userAgent: navigator.userAgent,
    };
  }

  static error(e: any) {
    console.error(e);
  }

  private static sendErrorToBackend(errorLog: any) {
    LogAPI.error(errorLog).catch((apiError) => {
      console.error('Failed to send error to backend:', apiError);
    });
  }

  static debug(...args: any[]) {
    if (process.env.NODE_ENV === 'development') {
      console.log(...args);
    }
  }

  static errorUI(
    error: Error,
    componentName: string,
    params?: Record<string, any>,
  ) {
    const errorLog: ErrorLogBase = {
      type: 'FRONT_END_UI_ERROR',
      ...this.getCommonFields(),
      errorMessage: error.message,
      errorStack: error.stack,
      componentName,
      params,
    };

    this.error(error);
    this.sendErrorToBackend(errorLog);
  }

  static errorData(
    error: Error,
    storeName: string,
    methodName: string,
    params?: Record<string, any>,
  ) {
    const errorLog: ErrorLogBase = {
      type: 'FRONT_END_DATA_ERROR',
      ...this.getCommonFields(),
      errorMessage: error.message,
      errorStack: error.stack,
      storeName,
      methodName,
      params,
    };

    this.error(error);
    this.sendErrorToBackend(errorLog);
  }

  static errorGeneral(error: Error, params?: Record<string, any>) {
    const errorLog: ErrorLogBase = {
      type: 'FRONT_END_GENERAL_ERROR',
      ...this.getCommonFields(),
      errorMessage: error.message,
      errorStack: error.stack,
      params,
    };

    this.error(error);
    this.sendErrorToBackend(errorLog);
  }
}
