export default {
  aaCheckout: {
    validateMessage: {
      emailError: '请输入一个有效的电子邮箱地址（如：example@email.com）',
      childAgeError: '在出发当日{age}至{max}周岁(不满)的乘客为儿童乘客',
      familyNameMaxError:
        '已达{number}最高字符限制。请将其余姓氏输入名称栏中。',
      genericMaxError: 'Max allowed character is {max}.',
      adultAgeError: '乘客在出发当日须年满{age}周岁',
      alphanumericError: '请仅使用英文字母(A至Z)及数字，不支持特殊字符或符号。',
      givenNameMaxError: '已达{number}最高字符限制。请将其余名称输入姓氏栏中。',
      mobileNumberError: '请输入有效的手机号码。',
      numericError: '请仅输入数字',
      dateError: '请输入有效的日期',
      totalNameMaxError: '姓氏及名称不可超过{number}总字符。',
      dateExpiredAfter: '您的护照在旅行当日须至少拥有6个月的有效期',
      alphabetError: '请仅使用英文字母(A至Z)，不支持特殊字符或符号。',
      firstNameMaxError: '已达{number}最高字符限制。请将其余名称输入姓氏栏中。',
      infantAgeError: '在出发当日{max}周岁以下的乘客为婴儿乘客',
      dateExpired: '您的旅游文件信息已失效',
      requiredError: '必填项',
      gstError: 'Please enter a valid GSTIN number (e.g. 18AABCU9603R1ZM)',
      vatError: 'Please enter a valid VAT Tin number (e.g. L231231231234)',
      yptaWarning:
        '年龄介于{0}至{1}岁的乘客，请阅读独自旅行青少年乘客规则(YPTA)。',
      yptaValidationMessage:
        '旅行当日年龄介于12至16周岁青少年须携带政府颁发带照片ID，并在父母或监护人的陪同下前往机场值机柜台办理值机。青少年乘客搭乘航班需符合我们的YPTA(独自旅行青少年)政策。',
    },
    guestDetailsForm: {
      adult: '成人',
      child: '儿童',
      infant: '婴儿',
      seniorNoteHeader: '老年人折扣',
      infantSubtitle: '{max} 周岁以下',
      Error: '未正确填写',
      ofwNoteHeader:
        '根据菲律宾共和国法案第8042条(经第10022条修订)，菲律宾海外劳务人员无需支付国际旅客服务费(IPSC)或机场税。\n\n菲律宾海外劳务人员需输入菲律宾海外劳务人员ID以移除预订中的国际旅客服务费。\n\n须于机场出示有效证件以供验证，否则或被处以罚款或被拒绝登机。',
      seniorCitizenId: '老年人ID',
      registeredSenior: '您是拥有注册ID的老年人吗？',
      pwdId: 'PWD ID',
      travelingWith: '同行乘客：{0}',
      id: '身份证',
      yptaDisclaimerText:
        '旅行当日年龄介于12至16周岁青少年须携带政府颁发带照片ID，并在父母或监护人的陪同下前往机场值机柜台办理值机。青少年乘客搭乘航班需符合我们的YPTA(独自旅行青少年)政策。',
      yes: '是的',
      governmentId: 'Government ID',
      Complete: '完整',
      noRightKeywordLabel: '我们无法根据您的搜索找到任何结果',
      expiryDate: '失效日期(自由选填)',
      dateOfBirth: '出生日期',
      passport: '护照',
      ypta: 'Young passenger travelling alone',
      passportDetails: '旅游文件信息',
      ToComplete: '待填写',
      childSubtitle: '{age} - {max}周岁',
      searchNationality: '按国家/地区搜索',
      yptaDisclaimerTitle: '独自旅行青少年乘客',
      ofwId: 'OFW ID',
      pwdNoteHeader:
        '根据经共和国法令(R.A.) 10754修订的第7277扩大残疾人 (PWD) 福利和特权法案，残疾人士输入残疾人士卡号可尊享国内航班基本票价8折优惠。残疾人士须在值机柜台出示残疾人士卡以及任何有效身份证件以供验证，否则或需支付1,500菲律宾比索罚款及票价差额或被拒绝登机。\n  requiredError: 此字段为必填字段。',
      seniorNote:
        '根据菲律宾相关法规，60岁以上的年长公民可以凭借自己的Senior Citizen ID享受菲律宾境内航班基础航费8折优惠。年长公民须在值机柜台出示自己的Senior Citizen证书及相关有效身份证件。若不能出示自己的Senior Citizen证书及相关有效身份证件，则会面临1,500菲律宾比索的罚款，同时须补齐航费差价，或被拒绝登机。',
      familyName: '姓氏',
      visaDisclaimerContent: '请确保您持有进入中转及/或最终目的地的旅游文件。',
      dateFormat: '日日/月月/年年年年',
      adultSubtitle: '{age}周岁级以上',
      popoverTitle: '姓名填写指南',
      nationality: '国籍/地区',
      isOfw: '您属于菲律宾海外劳工（OFW）吗？',
      Incomplete: '不完整',
      gender: '性别',
      clear: '清除',
      expiredError: '护照已失效',
      expiryDateRequired: '失效日期',
      no: '不是',
      personalDetails: '个人信息',
      passportNumber: '护照/身份证号码',
      documentNumber: '身份证号码',
      type: '旅行证件类型',
      docNumber: '身份证号',
      yptaDisclaimerReadMore:
        '阅读更多 https://support.airasia.com/s/article/Are-children-allowed-to-travel-alone?language=zh_CN',
      givenName: '名',
      registeredPwd: '您是注册的残疾人士吗？',
      popoverMessage:
        '姓名须与身份证件或护照上的英文字母(A至Z)一致，不支持特殊字符或符号。若您的姓名仅有1个字，请将其填写在姓氏及名称中。',
      visaDisclaimerTitle: '您或许需要为本次旅行办理签证。',
    },
    ancillary: {
      baggage: {
        baggageExperience: {
          budnleInclude: '常飞客包含 {1} 公斤托运行李限额。',
          depart: '出发航班',
          handCarryToolTipLabel:
            '这是您可以带入飞机的行李。您须将其存放入客舱行李架中。',
          return: '往返航班',
          sportsEquipmentLabel: '我需要运输体育器材',
          buttonTitle: '添加行李限额',
          submitButtonTitle: '完成',
          total: '总价',
          chooseSelection: '选择行李',
          handCarry: '手提包',
          title: '行李',
          summaryButtonText: '变更',
          handCarryToolTipTitle: '什么是手提行李？',
          notificationMessage: '我们已为您预先选定行李',
          checkBoxTitle: '为全部乘客选择一样的行李限额',
          sportsEquip: '运动装备',
          includedIcon: '包含于增值服务套餐中',
          includedBaggage:
            '提前预订价格最优惠，所示价格适用于您行程中的全部航班。',
          bundle: '套餐',
          included: '包括',
          handCarrySizeDesc: '每件行李尺寸不得超过56 x 36 x 23厘米',
          tileDescription:
            '提前预订价格最优惠，所示价格适用于您行程中的全部航班。',
          none: '无',
          priceDescription: '行李限额总费用',
          checkedBaggage: '托运行李',
          nextFlightButtonTitle: '下趟航班',
        },
      },
      insurance: {
        benefitsSubtitle: '{total_benefits} benefits大福利',
        continueWithInsuranceCta: '添加保险',
        continueWithoutInsuranceCta: '不添加保险，继续下一步',
        priceSubtitle: '适用{total_pax}名乘客',
        headerTitle: '保险',
        readMoreText: '了解详情',
        paxCount: '适用{paxcount}名乘客',
        headerMessage: '防止旅行意外情况。',
        termsText: '选择保险即代表我同意',
        termsAndConditionCloseButtonText: '关闭',
        discountedPercentage: '{discountedPercent}%折扣',
        termsLinkText: '条款与条件',
      },
      wheelchairSelector: {
        unavailablePrimaryBtn: '在没有轮椅服务下继续',
        nextFlightButtonTitle: '下趟航班',
        pwdNoteTitle: '什么是PWD ID？',
        pwdNote:
          'PWD ID是残疾人身份证明。您需要在柜台办理值机时出示此证件以确认其有效性。我们将在机场收取适用的费用。',
        buttonTitle: '申请',
        pwdSubTitle: '如果您有残疾人身份证，我们将免除轮椅服务费用。',
        cardDescription: '特殊需求乘客额外支持',
        needWheelchair: '我需要轮椅',
        unavailable: '不可选',
        title: '特殊帮助',
        wheelchairAvailabilityErrorTitle: '抱歉，出现错误！',
        submitButtonTitle: '完成',
        unavailableSubTitle1:
          '由于无法提供为本次旅程选择的轮椅，我们已从您的预订中删除了该选项。',
        errorTextTitle: '无法预订特殊帮助',
        available: '剩余{0}个',
        pwdFooter:
          '在特殊情况下，我们可能接受托运移除电池的电动轮椅或行动辅助器具（乘客需自行移除电池），且轮椅或行动辅助器具的重量限额为 85 公斤 。',
        readMore: '了解详情',
        pwdTitle: '输入您的PWD ID(可选)',
        ownWheelchair: '我自己有轮椅',
        noWheelchairOptionLabel: '我有自己的轮椅，不需要帮助',
        waiveWheelchairFee: '已免除轮椅费',
        unavailableSubTitle2:
          '您可以在没有轮椅的情况下继续前往支付页面，或选择其他可提供轮椅服务的航班。',
        pwdTextLabel: '身份证号码',
        unavailableSecondaryBtn: '变更航班',
        errorTextMessage: '可于机场进行申请，先到先得',
        wheelchairAvailabilityErrorMessage:
          '加载特殊帮助时出现问题，请刷新页面！',
        wheelchairFooterMessage: '机场供应轮椅数量有限，先到先得',
        priceDescription: '轮椅总费用',
        refresh: '更新',
        backToAddons: '返回增值服务',
        unavailableTitle: '无法提供轮椅服务',
        total: '总价',
        summaryButtonText: '变更',
      },
      meal: {
        errorTextTitle: '错误',
        errorTextMessage: '加载座位数据时发生错误，请重新尝试！',
      },
      seat: {
        notAvailabeError: '很抱歉，此航班暂不提供座位选择！',
        loadDataError: '加载座位数据时发生错误，请重新尝试！',
      },
    },
  },
};
